/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';

const AcceptCookie = ({ cookies, showAccepCookies, showMoreInfo = false }) => {
  const [moreInfo, setMoreInfo] = useState(false);
  const [activeTab, setActiveTab] = useState({
    tab1: 'active',
    tab2: '',
    tab3: '',
    openMenu: false,
  });
  const [technicalCookie, setTechnicalCookie] = useState({
    performace: false,
    follow: false,
  });
  // const [checkFollow, setCheckFollow] = useState(false);

  useEffect(() => {
    const dwTechnicalCookie = cookies.get('dw_Technical_cookie');
    const dwAdvertisementCookie = cookies.get('dw_Advertisement_cookie');
    setTechnicalCookie({
      follow:
        (dwAdvertisementCookie === 'opt-in' &&
          dwTechnicalCookie === 'opt-out') ||
        (dwAdvertisementCookie === 'opt-in' && dwTechnicalCookie === 'opt-in'),
      performace: dwTechnicalCookie === 'opt-in',
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (showMoreInfo) setMoreInfo(true);
  }, [showMoreInfo]);

  const onClickAccept = (option) => {
    if (option === 'all') {
      cookies.set('dw_Advertisement_cookie', 'opt-in');
      cookies.set('dw_Technical_cookie', 'opt-in');
    } else if (!technicalCookie.performace && !technicalCookie.follow) {
      cookies.set('dw_Advertisement_cookie', 'opt-out');
      cookies.set('dw_Technical_cookie', 'opt-out');
    } else if (technicalCookie.follow && !technicalCookie.performace) {
      cookies.set('dw_Advertisement_cookie', 'opt-in');
      cookies.set('dw_Technical_cookie', 'opt-out');
    } else if (!technicalCookie.follow && technicalCookie.performace) {
      cookies.set('dw_Advertisement_cookie', 'opt-out');
      cookies.set('dw_Technical_cookie', 'opt-in');
    } else if (technicalCookie.follow && technicalCookie.performace) {
      cookies.set('dw_Advertisement_cookie', 'opt-in');
      cookies.set('dw_Technical_cookie', 'opt-in');
    }

    showAccepCookies(false);
  };

  return (
    <div
      className={`js-cookie-consent-container cookie-consent__container ${
        moreInfo ? 'cookie-consent__container--fixed' : ''
      }`}
      data-cookie-expiration="90"
      data-technical-cookie="opt-in"
      data-advertisement-cookie="opt-out"
      style={{ zIndex: 99999999 }}
    >
      <div
        className={`js-cookie-banner cookie-consent__banner ${
          moreInfo ? 'cookie-consent__banner--hidden' : ''
        }`}
      >
        <div className="cookie-consent__msg js-cookie-msg">
          <div className="content-asset">
            <p>
              Al hacer clic en "Aceptar todas las cookies", usted acepta que las
              cookies se guarden en su dispositivo para mejorar la navegación
              del sitio, analizar el uso del mismo, y colaborar con nuestros
              estudios para marketing con nicotina o tabaco. .{' '}
            </p>
          </div>
        </div>
        <div className="cookie-consent__atc">
          <button
            className="cookie-consent__btn cookie-consent__btn--dark cookie-consent__btn--silent js-button-accept js-button-more-info"
            id="cookie-info"
            onClick={() => setMoreInfo(true)}
          >
            Más información
          </button>
          <button
            className="cookie-consent__btn cookie-consent__btn--dark js-button-accept"
            id="accept-all-cookies"
            onClick={() => onClickAccept('all')}
          >
            Aceptar todo
          </button>
        </div>
      </div>
      {/* mas info */}
      <div
        className={`js-cookie-settings cookie-consent__settings ${
          moreInfo ? '' : 'cookie-consent__settings--hidden'
        }`}
      >
        <div className="cookie-consent__tabs tabs">
          <nav
            role="navigation"
            className="js-cookie-tabs cookie-consent__tabs-nav"
          >
            <ul
              className={`cookie-consent__tabs-links ${
                activeTab.openMenu ? 'open' : ''
              }`}
            >
              <li className="cookie-consent__tabs-link">
                <a
                  // href="javascript:void(0)"
                  className={activeTab.tab1}
                  onClick={() =>
                    setActiveTab({
                      tab1: 'active',
                      tab2: '',
                      tab3: '',
                      openMenu: !activeTab.openMenu,
                    })
                  }
                >
                  Operacional
                </a>
              </li>
              <li className="cookie-consent__tabs-link">
                <a
                  // href="javascript:void(0)"
                  className={activeTab.tab2}
                  onClick={() =>
                    setActiveTab({
                      tab1: '',
                      tab2: 'active',
                      tab3: '',
                      openMenu: !activeTab.openMenu,
                    })
                  }
                >
                  Rendimiento
                </a>
              </li>
              <li className="cookie-consent__tabs-link">
                <a
                  // href="javascript:void(0)"
                  className={activeTab.tab3}
                  onClick={() =>
                    setActiveTab({
                      tab1: '',
                      tab2: '',
                      tab3: 'active',
                      openMenu: !activeTab.openMenu,
                    })
                  }
                >
                  Seguimiento y publicidad
                </a>
              </li>
            </ul>
          </nav>
          <div className="cookie-consent__tab-container">
            <div className={`cookie-consent__tab ${activeTab.tab1}`} id="tab-1">
              <div className="cookie-consent__msg cookie-consent__msg--fixed">
                <div className="content-asset">
                  <h2 className="title-info-cookies">Cookies operativas.</h2>
                  <p>
                    {' '}
                    Proporcionar el sitio y sus características. Ejemplo:
                    Mostrar apropiadamente el contenido en tu dispositivo.
                    También proveer el sitio y sus características con el
                    contenido más conveniente. Ejemplos: Recordar quién eres,
                    tus preferencias y tu configuración, para que no tengas que
                    ingresar tus datos cada vez que cambies de página, si
                    seleccionas tu idioma preferido o configuración regional.
                  </p>

                  <p>
                    {' '}
                    Estas cookies son esenciales para operar el sitio y que
                    puedas utlizar las funciones que que requieres, por lo que
                    esta herramienta no es opcional. No obstante, si lo deseas,
                    puedes configurar tu navegador para que rechace todas las
                    cookies, pero en este caso, es posible que no puedas
                    visualizar el sitio correctamente y que las funciones que
                    selecciones no estén disponibles.
                  </p>
                </div>
              </div>
              <hr />
              <button
                className="cookie-consent__btn cookie-consent__btn--dark js-save-cookie"
                onClick={() => onClickAccept('save')}
              >
                Salvar
              </button>
            </div>
            <div className={`cookie-consent__tab ${activeTab.tab2}`} id="tab-2">
              <div className="cookie-consent__msg cookie-consent__msg--fixed">
                <div className="content-asset">
                  <h2 className="title-info-cookies">
                    Cookies de rendimiento.
                  </h2>
                  <p>
                    Permítenos mejorar la forma en que funciona nuestro sitio.
                    Por ejemplo: Entender la forma en que los usuarios navegan
                    por nuestro sitio o qué tan bien funciona. Solo recibimos
                    información anónima de estas tecnologías: La información
                    recopilada se reduce para que solo identifique el área donde
                    te encuentras, luego se agrega, antes de que la recibamos.
                  </p>
                </div>
              </div>
              <label className="cookie-consent__cb-label">
                <input
                  type="checkbox"
                  className="js-cookie-checkbox"
                  name="dw_Technical_cookie"
                  data-name="performance"
                  checked={technicalCookie.performace}
                  onClick={() =>
                    setTechnicalCookie({
                      ...technicalCookie,
                      performace: !technicalCookie.performace,
                    })
                  }
                />
                <span className="cookie-consent__cb-input" />
                Aceptar
              </label>
              <hr />
              <button
                className="cookie-consent__btn cookie-consent__btn--dark js-save-cookie"
                onClick={() => onClickAccept('save')}
              >
                Salvar
              </button>
            </div>
            <div className={`cookie-consent__tab ${activeTab.tab3}`} id="tab-3">
              <div className="cookie-consent__msg cookie-consent__msg--fixed">
                <div className="content-asset">
                  <h2 className="title-info-cookies">
                    Cookies de seguimiento y publicidad.
                  </h2>
                  <p>
                    Hace un seguimiento del historial de navegación en este y
                    otros sitios para ofrecerte una comunicación más
                    personalizada. Ejemplo: Algunos de nuestros proveedores de
                    servicios contratan a terceros para rastrear, analizar el
                    uso y el volumen de información estadística de las personas
                    que visitan su plataforma digital.
                  </p>
                  <p />
                </div>
              </div>
              <label className="cookie-consent__cb-label">
                <input
                  type="checkbox"
                  className="js-cookie-checkbox"
                  name="dw_Advertisement_cookie"
                  data-name="advertising"
                  checked={technicalCookie.follow}
                  onClick={() => {
                    // setCheckFollow(!checkFollow);
                    setTechnicalCookie({
                      ...technicalCookie,
                      follow: !technicalCookie.follow,
                    });
                  }}
                />
                <span className="cookie-consent__cb-input" />
                Aceptar
              </label>
              <hr />
              <button
                className="cookie-consent__btn cookie-consent__btn--dark js-save-cookie"
                onClick={() => onClickAccept('save')}
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AcceptCookie.propTypes = {
  cookies: PropTypes.any,
  showAccepCookies: PropTypes.func,
  showMoreInfo: PropTypes.bool,
};

export default AcceptCookie;
