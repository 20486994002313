/* eslint-disable import/no-dynamic-require */

/* eslint-disable global-require */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import {
  GET_REFERRED_BY_EMAIL,
  GET_REFERRED_BY_IDENTIFICATION,
} from '../../graphql';
import { setCodeIqos } from '../../store/codeIqosSlice';
import { Event } from '../Tracking';
import { ShowInfo } from './index';

const LandingForm = ({ form }) => {
  const dispatch = useDispatch();

  const EMAIL = 'landing.search.email';
  const ID = 'landing.search.identification';
  const OPTIONS = [ID, EMAIL];

  const [filters, setFilters] = useState('');
  const [searchType, setSearchType] = useState(ID);
  const [code, setCode] = useState(undefined);
  // const [anycode, setAnycode] = useState(false);
  const [selectedItems, setSelectedItems] = useState(ID);
  const { t } = useTranslation();
  const { Option } = Select;

  const { loading: loadingCodeByEmail, data: dataCodeByEmail } = useQuery(
    GET_REFERRED_BY_EMAIL,
    {
      skip: filters === '' || searchType !== EMAIL,
      variables: {
        email: filters,
      },
      fetchPolicy: 'network-only',
    }
  );

  const {
    loading: loadingCodeByIdentification,
    data: dataCodeByIdentification,
  } = useQuery(GET_REFERRED_BY_IDENTIFICATION, {
    skip: filters === '' || searchType !== ID,
    variables: {
      identification: filters,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const codeFind =
      dataCodeByEmail?.referredByEmail?.code ||
      dataCodeByIdentification?.referredByIdentification?.code ||
      undefined;

    setCode(codeFind);
    dispatch(
      setCodeIqos({
        code: codeFind,
        filters,
      })
    );
    if (codeFind)
      setTimeout(() => {
        // document.getElementById('inputInfo').focus();
      }, 200);
  }, [dataCodeByEmail, dataCodeByIdentification, dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        const searchBy = searchType === ID ? 'por cédula' : 'por email';

        Event('Consult', 'Consulta código', `Consulta ${searchBy}`);

        // if (values.codeSearch !== code) setCode(null);
        if (!values.codeSearch) return;
        if (code) document.getElementById('inputInfo').focus();
        //  setAnycode(true);
        setFilters(values.codeSearch);
      } else {
        //  setAnycode(false);
      }
    });
  };

  const handleChange = (filter, value) => {
    // && form.getFieldValue('codeSearch')
    if (filter === 'landingSearchType') {
      // setCode(null);
      setFilters(form.getFieldValue('codeSearch'));
      setSearchType(value);
      setSelectedItems(value);
    }
  };

  const changeInput = (e) => {
    if (!e.target.value) {
      setCode('undefined');
      //  setAnycode(false);
    }
  };

  const filteredOptions = OPTIONS.filter((o) => !selectedItems.includes(o));
  const selectBefore = (
    <Select
      className="search-select"
      defaultValue={
        // <img
        //   id={EMAIL.split('.').join('-')}
        //   style={{ width: '2em', height: '1.4em' }}
        //   className=""
        //   src={require('../../assets/img/search_email.png')}
        //   alt="email"
        // />
        <img
          id={ID.split('.').join('-')}
          style={{ width: '2em', height: '1.4em' }}
          className=""
          src={require('../../assets/img/search_id.svg')}
          alt="id"
        />
      }
      style={{ width: 120 }}
      onChange={(value) => handleChange('landingSearchType', value)}
    >
      {filteredOptions.map((item) => (
        <Option key={item} value={item}>
          <img
            id={item.split('.').join('-')}
            style={{ width: '2em', height: '1.4em' }}
            className=""
            src={require(`../../assets/img/${
              item === EMAIL ? 'search_email.svg' : 'search_id.svg'
            }`)}
            alt="search"
          />
        </Option>
      ))}
    </Select>
  );

  const selectAfter = (
    <Button
      className="search-input-after"
      type="link"
      htmlType="submit"
      title="Ir"
      icon={loadingCodeByEmail || loadingCodeByIdentification ? 'loading' : ''}
    >
      Ir
    </Button>
  );

  const ColDefaultProps = {
    md: { span: 8, offset: 2 },
    sm: { span: 20, offset: 2 },
  };

  return (
    <>
      <div className="section-landingForm">
        <Row gutter={0}>
          <Col {...ColDefaultProps}>
            <h1>{t('sessionThree.title')}</h1>
            <p>{t('sessionThree.description')}</p>
            <Form
              layout="inline"
              onSubmit={handleSubmit}
              className="form-search"
            >
              <Form.Item>
                {form.getFieldDecorator('codeSearch', {
                  rules: [{ required: true, message: t('rules.codeSearch') }],
                })(
                  <Input
                    className="search-input"
                    allowClear
                    addonBefore={selectBefore}
                    addonAfter={selectAfter}
                    onChange={changeInput}
                  />
                )}
              </Form.Item>
            </Form>
          </Col>
          <Col
            className="landingForm-divisor"
            md={{ span: 2, offset: 0 }}
            sm={{ span: 20, offset: 2 }}
          />
          <Col {...ColDefaultProps}>
            <ShowInfo />
          </Col>
        </Row>
      </div>
    </>
  );
};

LandingForm.propTypes = {
  form: PropTypes.object,
};

export default Form.create({ name: 'landing' })(LandingForm);
