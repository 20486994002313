import { createUploadLink } from 'apollo-upload-client';

const httpLink = createUploadLink({
  uri:
    document
      .querySelector(`meta[property='og:server-url']`)
      .getAttribute('content') ||
    process.env.REACT_APP_ENDPOINT ||
    'http://localhost:4000',
});

export default httpLink;
