/* eslint-disable global-require */
import React, { useEffect, useState } from 'react';
// import QRCode from 'react-qr-code';
import { useTranslation } from 'react-i18next';
import { EmailShareButton, WhatsappShareButton } from 'react-share';
import { Button, Col, Icon, Input, Modal, Row, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/react-hooks';
import { setglobalLoading } from '../../store/globalLoadingSlice';
import { CODE_QR_IMAGE } from '../../graphql';
import { Event } from '../Tracking';

const ShowInfo = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [preview, setPreview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentCode, setCurrentCode] = useState(undefined);

  const { code: codeIqos, filters } =
    useSelector((state) => state?.codeIqos) || {};

  const [
    getCodeShare,
    { loading: loadingCode, data: codeQR, error: errorCode },
  ] = useLazyQuery(CODE_QR_IMAGE);

  const showCodeDownload = () => {
    dispatch(setglobalLoading({ loading: false }));
    const nodePreview = document.getElementById('previewQR');

    if (nodePreview && nodePreview?.childNodes?.length > 0)
      nodePreview.removeChild(nodePreview.childNodes[0]);

    const img = new Image();
    img.src = `data:image/jpeg;base64,${codeQR?.codeQRImage}`;
    img.id = 'download_image';
    document.getElementById('previewQR').appendChild(img);
  };

  useEffect(() => {
    if (errorCode) {
      dispatch(setglobalLoading({ loading: false }));
      setShowModal(false);
      return;
    }
    if (!loadingCode && codeQR) {
      showCodeDownload();
    }
    // eslint-disable-next-line
    }, [codeQR]);

  const downloadImage = () => {
    setLoading(true);
    const img = document.getElementById('download_image');
    const link = document.createElement('a');
    link.download = 'shareQR.png';
    link.href = img.src;
    link.click();
    setLoading(false);
  };

  const previewImage = () => {
    setPreview(true);
    setCurrentCode(codeIqos);
    getCodeShare({ variables: { code: codeIqos } });
  };

  return (
    <div className="section-share-code">
      <Row align="middle">
        <Col md={{ span: 20, offset: 2 }} sm={{ span: 24 }}>
          <h1>{t('Comparte tu código con amigos*')}</h1>
          <div className="container-input" align="center">
            <Input
              id="inputInfo"
              className="custom-input-info"
              value={codeIqos}
            />
          </div>
        </Col>
      </Row>
      {filters && !codeIqos && (
        <Row align="middle">
          <Col md={{ span: 20, offset: 2 }} sm={{ span: 24 }}>
            <p className="no-code">{t('sessionFour.noCode')}</p>
          </Col>
        </Row>
      )}
      {codeIqos && (
        <>
          <Row
            gutter={[0, 30]}
            justify="center"
            align="middle"
            style={{ paddingTop: 35 }}
          >
            <Col md={{ span: 16, offset: 4 }} sm={{ span: 20, offset: 2 }}>
              <h1>Compartir a través de:</h1>
            </Col>
          </Row>
          <Row>
            <Col
              md={{ span: 10, offset: 7 }}
              sm={{ span: 12, offset: 6 }}
              style={{ textAlign: 'center' }}
            >
              <EmailShareButton
                className="share-button"
                body={t('sessionFour.shareEmail').replace('#code', codeIqos)}
                subject={t('sessionFour.subjectEmail')}
                separator=""
                url=""
              >
                <Tooltip title={t('sessionFour.tooltipEmail')}>
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                  <img
                    // className="img-share"
                    onClick={() =>
                      Event('Share', 'Share click', 'Compartir por email')
                    }
                    src={require('../../assets/img/share_mail.png')}
                    alt="email"
                  />
                </Tooltip>
              </EmailShareButton>
              <WhatsappShareButton
                className="share-button"
                url={t('sessionFour.shareWhatsApp').replace('#code', codeIqos)}
                title=""
                separator=""
              >
                {(count) => count}
                <Tooltip title={t('sessionFour.tooltipWhatsApp')}>
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                  <img
                    // className="img-share"
                    onClick={() =>
                      Event('Share', 'Share click', 'Compartir por whatsapp')
                    }
                    src={require('../../assets/img/share_whatsapp.png')}
                    alt="whastapp"
                  />
                </Tooltip>
              </WhatsappShareButton>
            </Col>
          </Row>
          <Row gutter={[0, 40]} style={{ paddingTop: 20 }}>
            <Col span={22} offset={1} style={{ textAlign: 'center' }}>
              <Tooltip
                title="Haz clic acá para descargar tu código y compartirlo con tus amigos* por cualquier medio"
                placement="bottom"
              >
                <Button
                  className="custom-buttom-info cta2 primary-dark2 color-bg2"
                  type="default"
                  onClick={() => {
                    setPreview(false);
                    Event('Share', 'Save click', 'Guardar código');
                    dispatch(setglobalLoading({ loading: true }));
                    if (codeQR && codeIqos === currentCode) showCodeDownload();
                    else previewImage();
                    setShowModal(true);
                  }}
                >
                  Descarga tu código
                </Button>
              </Tooltip>
            </Col>
          </Row>
        </>
      )}
      <Modal
        title="Comparte tu código"
        visible={showModal}
        className="modal-share-code-img"
        onCancel={() => setShowModal(false)}
        centered
        style={preview ? { zIndex: 99999 } : {}}
        footer={
          <div>
            <Button onClick={() => setShowModal(false)}>Cerrar</Button>
            <Button
              className="button-download"
              type="primary"
              onClick={() => downloadImage()}
            >
              Descargar
              <Icon type={loading ? 'loading' : 'download'} />
            </Button>
          </div>
        }
      >
        <div className="preview-code" id="previewQR" />
      </Modal>
    </div>
  );
};

export default ShowInfo;
