/* eslint-disable global-require */
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Layout as AntLayout, Button} from 'antd';
import {Event} from '../Tracking';

/* const { Content, Sider } = AntLayout; */

const SessionPrimary = () => {
    const {t} = useTranslation();

    const onClickButton = () => {
        Event('Consult', 'Consulta click', 'Consulta código');
        document.getElementById('landing_codeSearch').focus();
    };

    return (<AntLayout className="custom-session-one">
        <div className="custom-container-one">
            <div className="sesion">
                <div className="columna columna-1">
                    <div className="fila fila-1">
                        <p className="boldLineHeight">{t('sessionOne.title')}</p>

                    </div>

                    <div className="fila fila-3">
                        <p className="content-disclaimer">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: t('sessionOne.subtitleComplement2'),
                                }}
                            />
                        </p>
                    </div>
                    <div className="fila fila-4">
                        <Button
                            className="cta2 primary-dark2 color-bg2"
                            type="primary"
                            onClick={onClickButton}
                        >
                            {t('sessionOne.titleButton')}
                        </Button>
                    </div>
                </div>
                <div className="columna columna-2">
                    <div className="img"/>
                </div>
            </div>
        </div>
    </AntLayout>);
};

export default SessionPrimary;
