import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import App from './App';
import { antdLocales } from '../Language';
import '../Language/i18nConfig';

const AppContainer = () => {
  const { i18n } = useTranslation();
  moment.locale(i18n.language);

  return <App language={antdLocales(i18n.language)} />;
};

export default AppContainer;
