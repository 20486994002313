import React from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import Routes from './Routes';
import Layout from './Layout';
import { routes } from '../../config/Routes';
import { routes as routeConstants } from '../../config/Constants';

const LayoutContainer = () => (
  <Switch>
    <Route
      exact
      path="/"
      render={() => (
        <Redirect to={`${routeConstants.URL_WITHOUT_LAYOUT}/tucodigo`} />
      )}
    />
    <Route
      path={routeConstants.URL_WITHOUT_LAYOUT}
      render={() => (
        <Layout>
          <Routes routes={routes.filter(({ layout }) => !layout)} redirect />
        </Layout>
      )}
    />
  </Switch>
);

export default withRouter(LayoutContainer);
