import React, { useCallback, useState } from 'react';
import * as PropTypes from 'prop-types';
import { ConfigProvider } from 'antd';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { Layout } from '../../components/Layout';
import AppSuspense from '../../components/Layout/AppSuspense';
import client from '../graphql/client';

const App = ({ language }) => {
  const [scope, setScope] = useState({ companyId: null });

  const handleScope = useCallback(({ companyId }) => {
    setScope({ companyId });
  }, []);

  return (
    <ConfigProvider locale={language}>
      <ApolloProvider client={client(scope)}>
        <Router>
          <AppSuspense>
            <Layout handleScope={handleScope} />
          </AppSuspense>
        </Router>
      </ApolloProvider>
    </ConfigProvider>
  );
};

App.propTypes = {
  language: PropTypes.object,
};

export default App;
