import { notification } from 'antd';

const notify = ({
  message,
  description,
  type = 'info',
  placement = 'topRight',
}) => notification[type]({ message, description, placement });

export default notify;
