import { onError } from 'apollo-link-error';
import { errorHandler } from '../../utils/errors';
import { routes } from '../Constants';

const errorLink = onError((errors) => {
  if (errors?.graphQLErrors?.length > 0) {
    const [error] = errors.graphQLErrors;
    errorHandler({
      errors: errors.graphQLErrors,
      category: error.extensions.category,
    });
  }

  if (
    errors?.networkError?.statusCode === 503 &&
    window.location.href.indexOf(
      `${window.location.protocol}://${window.location.host}${routes.URL_WITHOUT_LAYOUT}/maintenance/`
    ) === -1
  ) {
    window.location.replace(
      `${window.location.protocol}://${window.location.host}${
        routes.URL_WITHOUT_LAYOUT
      }/maintenance/${btoa(errors.networkError?.result?.message || '')}`
    );
  }
});

export default errorLink;
