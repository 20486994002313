import create from './create';
import asyncFiles from './asyncFiles';

const routes = [
  create({
    layout: false,
    url: '/tucodigo',
    component: asyncFiles.landing,
  }),
  create({
    layout: undefined,
    url: '/maintenance/:message?',
    component: asyncFiles.maintenance,
  }),
  // Must be always at the end
  create({
    layout: false,
    url: undefined,
    component: asyncFiles.notFound,
  }),
];

export default routes;
