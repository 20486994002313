import { gql } from 'apollo-boost';

export const STATUS = gql`
  query Status {
    status {
      code
      message
      retry
    }
  }
`;
