import React from 'react';
import * as PropTypes from 'prop-types';
import logo from '../../assets/img/referidos-logo.png';

const RawContainer = ({ children }) => (
  <>
    <div className="custom-page-container">
      <img className="custom-raw-logo" src={logo} alt="Referidos Logo" />
      {children}
    </div>
  </>
);

RawContainer.propTypes = {
  children: PropTypes.any,
};

export default RawContainer;
