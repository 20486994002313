/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable global-require */
import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import { Layout as AntLayout } from 'antd';
import Modal from 'react-modal';
import Cookies from 'universal-cookie';
import { AcceptCookie, AgeGate, SessionPrimary } from '../Landing';
import LandingFooter from './LandingFooter';
import { Event, initGA } from '../Tracking';

const { Header } = AntLayout;
Modal.setAppElement('#root');

const Layout = ({ children }) => {
  const { t } = useTranslation();
  // const codeIqos = useSelector((state) => state?.codeIqos?.code);
  const loading = useSelector((state) => state?.globalLoadingSlice?.loading);
  const [isOpen, setIsOpen] = useState(false);
  const [showAccepCookies, setShowAccepCookies] = useState(false);
  const [showMoreInfoCokie, setShowInfoCookie] = useState(false);

  const cookies = new Cookies();

  useEffect(() => {
    initGA('UA-69424936-25');
    const ageConfirmed = cookies.get('ageConfirmed_CO');
    const acceptCookies = cookies.get('dw_Advertisement_cookie');
    if (!ageConfirmed || ageConfirmed === 'false') {
      cookies.set('dw_Advertisement_cookie', 'opt-out');
      setIsOpen(true);
    }
    if (!acceptCookies) {
      setShowAccepCookies(true);
    }
    // eslint-disable-next-line
    }, []);

  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner
        text="Cargando..."
        className={loading ? 'custom-loading-main' : 'custom-loading'}
        // styles={{ content: { justify: 'center', align: 'center' } }}
        styles={{
          overlay: (base) => ({
            ...base,
            position: 'fixed',
          }),
        }}
      >
        <AntLayout className="custom-layout">
          <Header>
            <a
              href="https://co.iqos.com/es"
              target="_blank"
              onClick={() => Event('Top navigation', 'Logo click', 'IQOS logo')}
            >
              <img
                src={require('../../assets/img/logo-iqos.png')}
                alt={t('header.title')}
              />
            </a>
          </Header>
          <SessionPrimary Event={Event} />
          {children}
          {/* <AntLayout className="custom-session-two"> */}
          {/*  <Sider className="custom-sider-two"> */}
          {/*    <Registration code={codeIqos} /> */}
          {/*  </Sider> */}
          {/* </AntLayout> */}
          <LandingFooter
            showAccepCookies={setShowAccepCookies}
            showInfoCookies={setShowInfoCookie}
          />
        </AntLayout>
      </LoadingOverlay>
      <Modal
        isOpen={isOpen}
        contentLabel="My dialog"
        className="mymodal"
        onAfterOpen={() => (document.body.style.overflow = 'hidden')}
        onAfterClose={() => (document.body.style.overflow = 'unset')}
      >
        <AgeGate cookies={cookies} showAgeAgate={setIsOpen} />
      </Modal>
      {showAccepCookies && (
        <AcceptCookie
          cookies={cookies}
          showAccepCookies={setShowAccepCookies}
          showMoreInfo={showMoreInfoCokie}
        />
      )}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
};

export default Layout;
