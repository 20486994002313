import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
};

const globalLoading = createSlice({
  name: 'globalLoading',
  initialState,
  reducers: {
    setglobalLoading: (state, action) => action.payload,
    getglobalLoading: (state) => state,
  },
});

export default globalLoading.reducer;
export const { getglobalLoading, setglobalLoading } = globalLoading.actions;
