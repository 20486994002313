/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import * as PropTypes from 'prop-types';

const FloatLabel = (props) => {
  const [focus, setFocus] = useState(false);
  const { children, label, value } = props;

  const labelClass =
    focus || (value && value.length !== 0) ? 'label label-float' : 'label';

  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {children}
      <label className={labelClass}>{label}</label>
    </div>
  );
};

FloatLabel.propTypes = {
  children: PropTypes.any,
  label: PropTypes.string,
  value: PropTypes.any,
};

export default FloatLabel;
