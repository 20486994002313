import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  code: null,
  filters: null,
};

const codeIqos = createSlice({
  name: 'codeIqos',
  initialState,
  reducers: {
    setCodeIqos: (state, action) => action.payload,
    getCodeIqos: (state) => state,
  },
});

export default codeIqos.reducer;
export const { getCodeIqos, setCodeIqos } = codeIqos.actions;
