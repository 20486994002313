import { concatString } from './general';
import notify from './notify';
import { logout } from './authentication';
import { error } from '../config/Constants';

const resolveLevel = (errors) => {
  if (errors.find((x) => x.level === error.levels.error)) {
    return error.levels.error;
  }

  if (errors.find((x) => x.level === error.levels.warning)) {
    return error.levels.warning;
  }

  if (errors.find((x) => x.level === error.levels.info)) {
    return error.levels.info;
  }

  if (errors.find((x) => x.level === error.levels.success)) {
    return error.levels.success;
  }

  return error.levels.info;
};

const validation = ({ errors, category, answer }) => {
  let response;
  if (category === error.categories.validation) {
    response = errors.reduce(
      (result, { extensions, message }) => {
        const error = {
          message,
          ...extensions,
          fields: extensions.errors,
        };

        const msg = extensions.errors
          ? Object.keys(extensions.errors).reduce(
              (resultErrors, value) =>
                concatString(resultErrors, extensions.errors[value]),
              ''
            )
          : '';

        return {
          ...result,
          message: concatString(result.message, msg),
          errors: result.errors.concat([error]),
        };
      },
      { message: '', errors: [], show: true }
    );
  }

  return (
    (response && { ...response, type: resolveLevel(response.errors) }) || answer
  );
};

const authentication = ({ category, answer }) => {
  let response;
  if (category === error.categories.authentication) {
    logout();
  }

  return response || answer;
};

const general = ({ errors, category, answer }) => {
  let response;
  if (category === error.categories.general) {
    response = errors.reduce(
      (result, { extensions, message }) => {
        const error = {
          message,
          ...extensions,
        };

        return {
          ...result,
          message: concatString(result.message, message),
          type: extensions.level,
          errors: result.errors.concat([error]),
        };
      },
      { message: '', errors: [], show: true }
    );
  }

  return response || answer;
};

const internal = ({ category, answer }) => {
  let response;
  if (category === error.categories.internal) {
    response = {
      message: 'Ha ocurrido un error inesperado en el servidor',
      type: 'error',
      show: true,
    };
  }

  return response || answer;
};

export const errorMessage = (e) =>
  validation({
    errors: e?.graphQLErrors,
    category: error.categories.validation,
  });

export const errorHandler = ({ errors, category }) => {
  let answer;
  answer = validation({ errors, category, answer });
  answer = general({ errors, category, answer });
  answer = internal({ errors, category, answer });
  answer = authentication({ errors, category, answer });

  if (answer?.show) {
    notify(answer);
  }

  return answer;
};

export const errorEntityNotFound = (
  { graphQLErrors },
  history,
  route,
  condition
) => {
  const errors = graphQLErrors.filter(
    ({ extensions }) => extensions.category === error.categories.entityNotFound
  );

  if (condition || condition === undefined) {
    if (errors.length > 0) {
      history.push(route);
    }
  }
};
