import React from 'react';
import * as PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';

const Routes = ({ routes, user, redirect, setRecordOrder }) => (
  <Switch>
    {routes.map(({ component, exact, path }, index) => (
      <Route
        key={index}
        exact={exact}
        path={path}
        render={(props) =>
          React.createElement(
            component,
            {
              ...props,
              user,
              setRecordOrder,
            },
            null
          )
        }
      />
    ))}
    {redirect && <Route render={() => <Redirect to="/" />} />}
  </Switch>
);

Routes.propTypes = {
  redirect: PropTypes.bool,
  user: PropTypes.object,
  routes: PropTypes.array,
  setRecordOrder: PropTypes.func,
};

export default Routes;
