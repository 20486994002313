import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'antd';

const Complement = () => {
  const { t } = useTranslation();

  return (
    <div className="content-complement">
      <div className="content-complement-1">
        <div className="section-1">
          <div className="row-1">
            <p className="title">{t('sessionTwo.title')}</p>
          </div>
          <div className="row-2">
            <div className="column">
              <div className="row img-1" />
              <div className="row">
                <div
                  dangerouslySetInnerHTML={{ __html: t('sessionTwo.work1') }}
                />
              </div>
            </div>
            <div className="column">
              <div className="row img-2" />
              <div className="row">
                <div
                  dangerouslySetInnerHTML={{ __html: t('sessionTwo.work2') }}
                />
              </div>
            </div>
            <div className="column">
              <div className="row img-3" />
              <div className="row">
                <div
                  dangerouslySetInnerHTML={{ __html: t('sessionTwo.work3') }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-complement-2">
        <div className="section-2">
          <div className="row-1">
            <div className="two-rows">
              <p className="title">{t('sessionTwo.secondTitle')}</p>
              <p className="subtitle">{t('sessionTwo.secondSubtitle')}</p>
            </div>
          </div>
        </div>

        <Row gutter={[16, 16]} justify="center">
          <div
            style={{
              border: '2px solid #00d1d2',
              borderRadius: '8px',
              padding: '5px',
              width: '100%',
              boxSizing: 'border-box',
            }}
          >
            <Row gutter={[16, 16]} justify="center">
              <Col span={12}>
                <div style={{ textAlign: 'center', fontFamily: 'IQOS-Bold' }}>
                  <h2>{t('sessionTwo.promotions.iqosIluma')}</h2>
                </div>
              </Col>
              <Col span={12}>
                <div style={{ textAlign: 'center', fontFamily: 'IQOS-Bold' }}>
                  <h2>{t('sessionTwo.promotions.bondsByIqos')}</h2>
                </div>
              </Col>
            </Row>
          </div>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <Card
              style={{
                borderRadius: 10,
                backgroundColor: '#00d1d2',
                color: 'white',
              }}
            >
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <div className="device-1" />
                </Col>
                <Col
                  span={16}
                  xs={24}
                  sm={8}
                  style={{ fontFamily: 'IQOS-Bold', fontSize: 20 }}
                >
                  <Row>
                    <Col span={24}>
                      <h1 style={{ color: 'white' }}>
                        {t('sessionTwo.promotions.bonusForYou')}
                      </h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <h1>
                        <b>{t('sessionTwo.promotions.cardOne.price')}</b>
                      </h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <h1 style={{ color: 'white' }}>
                        {t('sessionTwo.promotions.forEachReferral')}
                      </h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <p>{t('sessionTwo.promotions.bringReferrals')}</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} sm={12}>
            <Card
              style={{
                borderRadius: 10,
                backgroundColor: '#00d1d2',
                color: 'white',
              }}
            >
              <Row gutter={[16, 16]}>
                <Col span={8} style={{ fontFamily: 'IQOS-Bold' }}>
                  <div className="device-3" />
                </Col>
                <Col
                  span={16}
                  xs={24}
                  sm={8}
                  style={{ fontFamily: 'IQOS-Bold', fontSize: 20 }}
                >
                  <Row>
                    <Col span={24}>
                      <h1 style={{ color: 'white' }}>
                        {t('sessionTwo.promotions.bonusForYou')}
                      </h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <h1>
                        <b>{t('sessionTwo.promotions.cardTwo.price')}</b>
                      </h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <h1 style={{ color: 'white' }}>
                        {t('sessionTwo.promotions.forEachReferral')}
                      </h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <p>{t('sessionTwo.promotions.bringReferrals')}</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row gutter={[16, 16]} justify="center">
          <Col span={24}>
            <div
              style={{
                border: '2px solid #00d1d2',
                borderRadius: '8px',
                padding: '5px',
                textAlign: 'center',
                fontFamily: 'IQOS-Bold',
              }}
            >
              <h2>{t('sessionTwo.promotions.friendDiscount')}</h2>
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24}>
            <Card
              style={{
                borderRadius: 10,
                backgroundColor: '#00d1d2',
                color: 'white',
                fontFamily: 'IQOS-Bold',
              }}
            >
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={8}>
                  <h1 style={{ color: 'white', fontSize: 60 }}>
                    <b>15% dcto</b>
                  </h1>
                </Col>
                <Col xs={24} sm={16}>
                  <h1 style={{ fontSize: 60 }}>
                    <b>En la compra del primer dispositivo</b>
                  </h1>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Complement;
