import ReactGA from 'react-ga4';

export const initGA = (trackingID) => {
  ReactGA.initialize(trackingID);
};

export const PageView = () => {
  // ReactGA.pageview(window.location.pathname + window.location.search);
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname + window.location.search,
    title: 'Page visit',
  });
};

/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 * @param {string} label
 */
export const Event = (category, action, label) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};

/**
 * Avoid register anything for change with Google Tag Manager
 * @param _category
 * @param _action
 * @param _label
 * @constructor
 */
export const EventBK = (_category, _action, _label) => {};
