/* eslint-disable global-require */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import { Select } from 'antd';
import FloatLabel from './FloatLabel';

const { Option } = Select;

const AgeGate = ({ cookies, showAgeAgate }) => {
  const [month, setMonth] = useState(undefined);
  const [year, setYear] = useState(undefined);
  const [ageConfirmed, setAgeConfirmed] = useState(
    cookies.get('ageConfirmed_CO')
  );

  const numberMonths = () => {
    const number = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 1; index < 13; index++) {
      number.push(index);
    }
    return number;
  };

  const numberYear = () => {
    const number = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 1940; index < new Date().getFullYear() + 1; index++) {
      number.push(index);
    }
    return number.sort((a, b) => b - a);
  };

  const validateAge = () => {
    const a = moment();
    const b = moment(`${month}-${year}`, 'MM-YYYY');
    const age = moment.duration(a.diff(b));
    const years = age.years();
    const current = new Date();
    const timeExpire = new Date();
    if (years >= 18) {
      timeExpire.setMonth(current.getMonth() + 1);
      cookies.set('age_confirmed', 1, { path: '/', expires: timeExpire });
      cookies.set('ageConfirmed_CO', true, { path: '/', expires: timeExpire });
      showAgeAgate(false);
    } else {
      timeExpire.setHours(current.getHours() + 3);
      cookies.set('age_confirmed', 0);
      cookies.set('ageConfirmed_CO', false, { path: '/', expires: timeExpire });
      setAgeConfirmed('false');
    }
  };

  const onClickConfirm = () => {
    validateAge();
  };

  return (
    <div className="region region-modals" data-nosnippet="">
      <div id="offcookieModal" className="modal offcookieModal modal">
        <div className="cookie-cnt">
          <div className="statement">
            <p>
              Las Cookies en tus páginas son utilizadas para dar una mejor
              experiencia al usuario. Si continuas, asumimos que estas de
              acuerdo con recibir Cookies de esta página.
            </p>
          </div>
          <button
            type="button"
            className="agree-button"
            role="button"
            data-close-modal="#cookieModal"
            data-data-modal="agree"
            data-no-blur="true"
            data-tracker="true"
            data-tracker-event="cookieModalClick"
            data-tracker-action="accept"
            data-tracker-label="cookieModalSelection"
          >
            <span className="ui-button-text">Sí</span>
          </button>
          <button
            type="button"
            className="deny-button"
            data-close-modal="#cookieModal"
            data-data-modal="deny"
            data-no-blur="true"
            data-tracker="true"
            data-tracker-event="cookieModalClick"
            data-tracker-action="cookie settings"
            data-tracker-label="cookieModalSelection"
          >
            <img
              src="/themes/custom/pmi_iqos/assets/images/icons/icon-cross-tan.svg"
              alt=""
            />
          </button>
        </div>
      </div>
      {/* Age Verification Modal */}
      <div
        id="ageModal"
        className="modal ageModal ui-dialog ui-widget ui-widget-content ui-corner-all ui-front dialog-age-verification-drupal dialog-no-close backModal birth-select modal-in"
        // tabIndex="0"
        role="dialog"
        aria-describedby="dialog-container"
        aria-labelledby="ui-id-2"
        min-age="18"
        style={{ display: 'block' }}
        //    onClick={() => {
        //   setVisibleMonth(false);
        // }}
      >
        <div className="ui-dialog-titlebar ui-widget-header ui-corner-all ui-helper-clearfix">
          <span id="ui-id-2" className="ui-dialog-title">
            &nbsp;
          </span>
          <button
            type="button"
            className="ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only ui-dialog-titlebar-close"
            role="button"
            title="Cerrar"
          >
            <span className="ui-button-icon-primary ui-icon ui-icon-closethick" />
            <span className="ui-button-text">Cerrar</span>
          </button>
        </div>
        <div
          id="dialog-container"
          className="dialog-content ui-dialog-content ui-widget-content"
        >
          <div
            data-step="step0"
            className={`gate-container ${
              !ageConfirmed || ageConfirmed === 'true' ? '' : 'hidden'
            }`}
          >
            <img
              // eslint-disable-next-line global-require
              // eslint-disable-next-line import/no-unresolved
              src={require('../../assets/img/logo-white.png')}
              alt=""
              className="primary-logo__img"
            />
            <div className="gate-container__text">
              <p>
                Por favor ingresa tu fecha de nacimiento para confirmar que eres
                un adulto usuario de productos con nicotina o tabaco.
              </p>
            </div>
            <div className="gate-container__selectwrapper" />
            <div className="ui-dialog-buttonpane">
              <div className="gate-container__selectwrapper birth_selector">
                <div className="select month-selector">
                  <FloatLabel label="MES*" name="name" value={month}>
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      onChange={(value) => setMonth(value)}
                      value={month}
                      mode="default"
                      showArrow
                      dropdownStyle={{ zIndex: 99999999999 }}
                      className="float-select"
                      allowClear={false}
                    >
                      {numberMonths().map((x) => (
                        <Option key={x} value={x}>
                          {x}
                        </Option>
                      ))}
                    </Select>
                  </FloatLabel>
                </div>
              </div>
              <div className="gate-container__selectwrapper birth_selector">
                <span className="error-select years">
                  Please select your year of birth
                </span>
                <div className="select year-selector">
                  <FloatLabel label="AÑO*" name="name" value={year}>
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      onChange={(value) => setYear(value)}
                      value={year}
                      mode="default"
                      showArrow
                      dropdownStyle={{ zIndex: 99999999999 }}
                      className="float-select"
                      allowClear={false}
                    >
                      {numberYear().map((x) => (
                        <Option key={x} value={x}>
                          {x}
                        </Option>
                      ))}
                    </Select>
                  </FloatLabel>
                </div>
              </div>
            </div>
            <div className="ui-dialog-buttonset">
              <button
                type="button"
                className="js-avs-dialog-accept cta primary-dark color-bg"
                data-age-modal="#ageModal"
                role="button"
                data-tracker="true"
                data-tracker-event="gatewayLegalAge"
                data-tracker-action="Confirm"
                disabled={!(month && year)}
                data-tracker-label="selectedLegalAgeBoolean"
                onClick={onClickConfirm}
              >
                Confirmar
              </button>
            </div>
            <div className="gate-container__age-confirm">
              <p>
                Esta página web contiene información sobre productos libres de
                humo y necesitamos tu edad para verificar que eres un adulto en
                Colombia que, de una u otra forma, continuaría fumando o usando
                otros productos con nicotina. ​Nuestros productos con nicotina y
                tabaco no son ni están diseñados como un mecanismo o alternativa
                para dejar de fumar.​ No son libre de riesgo. Las unidades de
                tabaco contienen nicotina la cual es adictiva. Para uso de
                adultos exclusivamente. Visita la página “Información
                Importante” de este sitio web, para más información sobre
                riesgos, nicotina o tabaco. 
              </p>
            </div>
          </div>
          <div
            data-step="step1"
            className={`gate-container ${
              ageConfirmed === 'false' ? '' : 'hidden'
            }`}
          >
            <p style={{ color: '#34303d' }}>
              Debes tener 18 años o más para acceder al contenido de este sitio
            </p>

            <p />
          </div>
        </div>
      </div>
    </div>
  );
};

AgeGate.propTypes = {
  cookies: PropTypes.any,
  showAgeAgate: PropTypes.func,
};

export default AgeGate;
