/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable global-require */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { Event } from '../Tracking';

const LandingFooter = () => {
  const { t } = useTranslation();
  const initialCollapse = {
    link: 'f-menu-cat collapse-submenu',
    service: 'f-menu-cat collapse-submenu',
    legal: 'f-menu-cat collapse-submenu',
    social: 'f-menu-cat collapse-submenu',
  };
  const [collapse, setCollapse] = useState(initialCollapse);

  const collapseList = (value) => {
    setCollapse({ ...initialCollapse, ...value });
  };

  const year = new Date().getFullYear();

  return (
    <>
      <div className="footers-wrapper">
        <footer className="footer">
          <div className="footer-legal-text">
            <p>
              {t('footer.legalText')}
              <br />
            </p>
          </div>
          <div className="footer__content">
            <div className="select language-selector">
              <ul className="select-options" style={{ display: 'none' }}>
                <li rel="/en">
                  <a href="/en">English</a>
                </li>
                <li rel="/es">
                  <a href="/es">Español</a>
                </li>
              </ul>
            </div>
            <ul className="js-footer-menu footer-menu">
              <li
                className="footer-menu__item"
                data-access=""
                onClick={() => collapseList({ link: 'f-menu-cat' })}
              >
                <h5 className="footer-menu__heading"> Links de ayuda </h5>
                <span className="collapse-arrow" />
                <ul className={collapse.link}>
                  <li className="f-menu-cat__item" data-access="Everyone ">
                    <a
                      href="https://co.iqos.com/es/tiendas"
                      className="f-menu-cat__item-link"
                      target=""
                      title="Dónde comprar"
                      onClick={() =>
                        Event('Footer', 'Footer click', 'Dónde comprar')
                      }
                    >
                      {' '}
                      Dónde comprar{' '}
                    </a>
                  </li>
                  <li className="f-menu-cat__item" data-access="Everyone ">
                    <a
                      href="https://co.iqos.com/es/noticias"
                      className="f-menu-cat__item-link"
                      target=""
                      title="Noticias &amp; Eventos"
                      onClick={() =>
                        Event('Footer', 'Footer click', 'Noticias & Eventos')
                      }
                    >
                      {' '}
                      Noticias{' '}
                    </a>
                  </li>
                  <li className="f-menu-cat__item" data-access="Everyone ">
                    <a
                      href="https://www.pmi.com/"
                      className="f-menu-cat__item-link"
                      // eslint-disable-next-line react/jsx-no-target-blank
                      target="_blank"
                      title="Ir a PMI.com"
                      onClick={() =>
                        Event('Footer', 'Footer click', 'Ir a PMI.com')
                      }
                    >
                      {' '}
                      Ir a PMI.com{' '}
                    </a>
                  </li>
                </ul>
              </li>
              <li
                className="footer-menu__item"
                data-access="Everyone "
                onClick={() => collapseList({ service: 'f-menu-cat' })}
              >
                <h5 className="footer-menu__heading"> Servicio al cliente </h5>
                <span className="collapse-arrow" />
                <ul className={collapse.service}>
                  <li className="f-menu-cat__item" data-access="Everyone ">
                    <a
                      href="https://co.iqos.com/es/contact-us"
                      className="f-menu-cat__item-link"
                      target=""
                      title="Contacto"
                      onClick={() =>
                        Event('Footer', 'Footer click', 'Contacto')
                      }
                    >
                      {' '}
                      Contacto{' '}
                    </a>
                  </li>
                  <li className="f-menu-cat__item" data-access="Everyone ">
                    <a
                      href="https://co.iqos.com/es/soporte/iqos/faq"
                      className="f-menu-cat__item-link"
                      target="_self"
                      title="Preguntas frecuentes"
                      onClick={() =>
                        Event('Footer', 'Footer click', 'Preguntas frecuentes')
                      }
                    >
                      {' '}
                      Preguntas frecuentes{' '}
                    </a>
                  </li>
                </ul>
              </li>
              <li
                className="footer-menu__item"
                data-access="Everyone "
                onClick={() => collapseList({ legal: 'f-menu-cat' })}
              >
                <span className="collapse-arrow" />
                <ul className={collapse.legal} />
              </li>
              <li
                className="footer-menu__item"
                data-access="Everyone "
                onClick={() => collapseList({ social: 'f-menu-cat' })}
                style={{ marginLeft: '366px' }}
              >
                <h5 className="footer-menu__heading"> Síguenos </h5>
                <span className="collapse-arrow" />
                <ul className={collapse.social}>
                  <li
                    className="f-menu-cat__item icon-social"
                    data-access="Everyone "
                  >
                    <a
                      href="https://www.facebook.com/iqos.co"
                      target="_blank"
                      title="Facebook"
                      onClick={() =>
                        Event('Footer', 'Footer click', 'Facebook')
                      }
                      style={{ color: '#ffffff', fontSize: '14px' }}
                    >
                      Facebook
                    </a>
                  </li>
                  <li
                    className="f-menu-cat__item icon-social"
                    data-access="Everyone "
                  >
                    <a
                      href="https://twitter.com/iqos_support_co"
                      target="_blank"
                      title="Twitter"
                      onClick={() => Event('Footer', 'Footer click', 'Twitter')}
                      style={{ color: '#ffffff', fontSize: '14px' }}
                    >
                      Twitter
                    </a>
                  </li>
                  <li
                    className="f-menu-cat__item icon-social"
                    data-access="Everyone "
                  >
                    <a
                      href="https://www.instagram.com/iqos_co/"
                      target="_blank"
                      title="Instagram"
                      onClick={() =>
                        Event('Footer', 'Footer click', 'Instagram')
                      }
                      style={{ color: '#ffffff', fontSize: '14px' }}
                    >
                      Instagram
                    </a>
                  </li>
                  <li
                    className="f-menu-cat__item icon-social"
                    data-access="Everyone "
                  >
                    <a
                      href="https://www.youtube.com/channel/UCdTyKnYzo2X1lpOg5V_fvRw/"
                      target="_blank"
                      title="Youtube"
                      onClick={() => Event('Footer', 'Footer click', 'Youtube')}
                      style={{ color: '#ffffff', fontSize: '14px' }}
                    >
                      Youtube
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <Row style={{ background: '#34303d', paddingTop: 20 }}>
            <Col md={{ span: 8, offset: 1 }} sm={{ span: 20, offset: 2 }}>
              <div className="footer-logo__crights">
                © {year} Philip Morris Products S.A. All rights reserved.
                <br /> COMPAÑÍA COLOMBIANA DE TABACO S.A.S – NIT 890.900.043-8
                <br />- Carrera 52 # 4-96 Medellín, Colombia
                <br />- Teléfono 01 8000 1 8000 1.
                <br />- Correo: contact.co@iqos.com.
                <br />- Notificación judicial: departamento.legal@pmi.com
              </div>
            </Col>
            <Col
              md={{ span: 15 }}
              sm={{ span: 24 }}
              style={{ textAlign: 'right' }}
            >
              <Row>
                <Col offset={8} span={4} style={{ color: '#8a8989' }}>
                  Legal
                </Col>
                <Col span={4}>
                  <img
                    alt="Cámara"
                    src={require('../../assets/img/formas_de_pago/camara.png')}
                  />
                </Col>
                <Col span={4}>
                  <img
                    alt="SIC"
                    src={require('../../assets/img/formas_de_pago/SIC.png')}
                  />
                </Col>
                {/* <Col md={{span: 4}} sm={{span: 0}} /> */}
              </Row>
              <Row>
                <Col className="footer-list">
                  <ul>
                    <li>
                      <a
                        href="https://co.iqos.com/es/legal#politica-de-privacidad"
                        // className="f-menu-cat__item-link"
                        target=""
                        title="Política de Tratamiento de datos personales"
                        onClick={() =>
                          Event(
                            'Footer',
                            'Footer click',
                            'Política de Tratamiento de datos personales'
                          )
                        }
                      >
                        {' '}
                        Política de Tratamiento de datos personales{' '}
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://co.iqos.com/es/legal#terminos-y-condiciones"
                        // className="f-menu-cat__item-link"
                        target=""
                        title="Términos y condiciones"
                        onClick={() =>
                          Event(
                            'Footer',
                            'Footer click',
                            'Términos y condiciones'
                          )
                        }
                      >
                        {' '}
                        Términos y condiciones{' '}
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://co.iqos.com/es/legal#Informacion-compania"
                        // className="f-menu-cat__item-link"
                        target=""
                        title="Información de la compañía"
                        onClick={() =>
                          Event(
                            'Footer',
                            'Footer click',
                            'Información de la compañía'
                          )
                        }
                      >
                        {' '}
                        Información de la compañía{' '}
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://co.iqos.com/es/legal#LICENCIA-PARA-CONTENIDO-GENERADO-POR-USUARIO"
                        // className="f-menu-cat__item-link"
                        target=""
                        title="Licencia para contenido generado por usuario"
                        onClick={() =>
                          Event(
                            'Footer',
                            'Footer click',
                            'Licencia para contenido generado por usuario'
                          )
                        }
                      >
                        {' '}
                        Licencia para contenido generado por usuario{' '}
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://co.iqos.com/es/legal#house-rules"
                        // className="f-menu-cat__item-link"
                        target=""
                        title=" Social Media House Rules"
                        onClick={() =>
                          Event(
                            'Footer',
                            'Footer click',
                            'Social Media House Rules'
                          )
                        }
                      >
                        {' '}
                        Social Media House Rules{' '}
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>{' '}
          <Row justify="center" gutter={[0, 20]}>
            <Col
              span={24}
              style={{ textAlign: 'center', background: '#34303d' }}
            >
              {/* OneTrust Cookies Settings button start */}
              <button
                id="ot-sdk-btn"
                className="ot-sdk-show-settings"
                style={{ marginTop: '15px' }}
                type="submit"
              >
                Preferencias de Cookies
              </button>
              {/* OneTrust Cookies Settings button end */}
            </Col>
          </Row>
        </footer>
      </div>
    </>
  );
};

export default LandingFooter;
