import React from 'react';
import { Select } from 'antd';
import { EditorState } from 'draft-js';

export const typeInit = ({ type, name }) => {
  switch (type) {
    case 'bool':
      return { [name]: false };
    case 'trueBool':
      return { [name]: true };
    case 'array':
      return { [name]: [] };
    case 'richText':
      return { [name]: EditorState.createEmpty() };
    case 'text':
    case 'number':
    case 'select':
    default:
      return { [name]: '' };
  }
};

export const isNullOrEmpty = (value) => !value || value === '';
export const initProp = (result, value) => ({ ...result, ...typeInit(value) });

export const concatString = (result, value) => {
  const concat = !isNullOrEmpty(result) ? `${result} ` : '';
  return `${concat}${!isNullOrEmpty(value) ? value : ''}`;
};

export const urlDecode = (url) => decodeURIComponent(url.replace(/\+/g, ' '));

export const selectOptions = ({ data, list, value, name }) =>
  data &&
  data[list] &&
  data[list].map((x) => (
    <Select.Option key={x[value]} value={x[value]} title={x[name]}>
      {x[name]}
    </Select.Option>
  ));

export const cloneAndGet = ({ origin, key, keyName }) => {
  const data = [...origin];
  const index = origin.indexOf(origin.find((x) => x[keyName] === key));

  return { data, index, row: data[index] };
};

export const nextKey = ({ list, value = 0, property = 'key' }) => {
  if (list.find((x) => x[property] === value)) {
    return nextKey({ list, value: value + 1, property });
  }

  return value;
};

export const matchString = (text, value) =>
  text
    ?.trim()
    ?.toLowerCase()
    .includes(value?.trim()?.toLowerCase() || '');

export const sortString = (a, b, property) => {
  if (a[property] < b[property]) {
    return -1;
  }

  if (a[property] > b[property]) {
    return 1;
  }

  return 0;
};

export const formSubmit = ({ e, form, handleSubmit }) => {
  e.preventDefault();
  handleSubmit(form);
};

export const iconResolver = ({ extension }) => {
  switch (extension) {
    case 'xls':
    case 'xlsx':
      return 'file-excel';
    case 'zip':
    case 'rar':
      return 'file-zip';
    case 'pdf':
      return 'file-pdf';
    case 'png':
    case 'jpg':
    case 'jpeg':
      return 'file-image';
    case 'doc':
    case 'docx':
      return 'file-word';
    default:
      return 'file';
  }
};

export const pathTreatment = (path) => {
  if (path?.split('/').length > 1) {
    return path.split('/')[1];
  }
  return '';
};

const getLevel = (result, current) => {
  if (current) {
    return result[current];
  }

  return result;
};

export const filterCharacters = (
  object,
  expresion = /[0-9]/g,
  next,
  depth = 'target.value',
  newValue = ''
) => {
  let value = depth.split('.').reduce(getLevel, object);
  value = String(value).replace(expresion, newValue);
  if (typeof next === 'function') {
    next(value);
  }
  return value;
};

export const createMarkup = (__html) => ({ __html });

export const currencyFormatter = (value) =>
  new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 0,
  }).format(value);
