import { gql } from 'apollo-boost';

export const GET_REFERRED_BY_EMAIL = gql`
  query ReferredByEmail($email: String!) {
    referredByEmail(email: $email) {
      id
      identification
      email
      code
    }
  }
`;

export const GET_REFERRED_BY_IDENTIFICATION = gql`
  query ReferredByIdentification($identification: String!) {
    referredByIdentification(identification: $identification) {
      id
      identification
      email
      code
    }
  }
`;

export const CODE_QR_IMAGE = gql`
  query CodeQRImage($code: String!) {
    codeQRImage(code: $code)
  }
`;

export const REGISTER_PLUS = gql`
  query RegisterPlus($code: String!) {
    registerPlus(code: $code)
  }
`;
